import React from 'react';

function AIAltoRischioPage() {
  return (
    <div>
      <h2>AI ad Alto Rischio</h2>
      <p>Information about high-risk AI systems.</p>
    </div>
  );
}

export default AIAltoRischioPage;
