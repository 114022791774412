import React from 'react';

function DefinizioneAmbitoPage() {
  return (
    <div>
      <h2>Definizione Ambito</h2>
      <p>Here you can define the scope of your project.</p>
    </div>
  );
}

export default DefinizioneAmbitoPage;
