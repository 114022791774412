import React, { useState, useEffect, useRef } from 'react';

function Chat({ user, path, questionId }) {
    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const messagesEndRef = useRef(null); // Per scrollare alla fine della chat automaticamente

    // Funzione per inviare il messaggio
    const handleSendMessage = async () => {
        if (inputValue.trim() === '') return;

        const userMessage = { text: inputValue, type: 'user' };
        setMessages(prevMessages => [...prevMessages, userMessage]);

        // Pulizia del campo di input
        setInputValue('');
        setLoading(true);

        try {
            const response = await fetch('https://eo7rgurwv3yq0hn.m.pipedream.net', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ query: inputValue })
            });

            const text = await response.text(); 
            let cleanText = text.replace(/[\u0000-\u0019]+/g, "");
            cleanText = cleanText.replace(/'/g, " "); 
            console.log(cleanText);
            let data;
            try {
                data = JSON.parse(cleanText);
            } catch (error) {
                console.error('Errore nel parsing della risposta JSON:', error);
                throw new Error('Invalid JSON response');
            }

            const botMessage = { text: data.response, type: 'bot' };
            setMessages(prevMessages => {
                const newMessages = [...prevMessages, botMessage];
                // Limita i messaggi a un massimo di 20
                return newMessages.slice(-20);
            });
        } catch (error) {
            console.error('Error sending message:', error);
            const errorMessage = { text: 'Errore nella ricezione della risposta. Riprova più tardi.', type: 'bot' };
            setMessages(prevMessages => {
                const newMessages = [...prevMessages, errorMessage];
                return newMessages.slice(-20);
            });
        } finally {
            setLoading(false);
            scrollToBottom(); // Scrolla in fondo quando arriva la risposta
        }
    };

    // Funzione per gestire l'invio tramite tasto invio
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSendMessage();
        }
    };

    // Funzione per scrollare automaticamente alla fine della chat
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom(); // Scrolla ogni volta che i messaggi cambiano
    }, [messages]);

    return (
        <div className="position-fixed bg-white p-3 border rounded shadow" style={{ bottom: '80px', right: '20px', width: '350px', height: '400px', display: 'flex', flexDirection: 'column' }}>
            <div className="d-flex flex-column flex-grow-1 overflow-auto mb-2">
                {messages.map((message, index) => (
                    <div key={index} className={`d-flex mb-2 ${message.type === 'user' ? 'justify-content-end' : 'justify-content-start'}`}>
                        <div className={`p-2 rounded ${message.type === 'user' ? 'bg-numina text-white' : 'bg-light text-dark'}`} style={{ maxWidth: '80%' }}>
                            {message.text}
                        </div>
                    </div>
                ))}
                {loading && (
                    <div className="d-flex justify-content-center my-2">
                        <div className="spinner-border text-danger" role="status">
                            <span className="visually-hidden">Caricamento...</span>
                        </div>
                    </div>
                )}
                <div ref={messagesEndRef} /> {/* Riferimento per scrollare */}
            </div>

            {/* Input per inserire il messaggio */}
            <div className="input-group">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Scrivi la tua domanda.."
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <button
                    className="btn btn-success"
                    onClick={handleSendMessage}
                    disabled={loading}
                >
                    Invia
                </button>
            </div>
        </div>
    );
}

export default Chat;
