import React from 'react';
import { Route, Routes } from 'react-router-dom';
import DashboardPage from '../pages/DashboardPage';
import DefinizioneAmbitoPage from '../pages/DefinizioneAmbitoPage';
import AIAltoRischioPage from '../pages/AIAltoRischioPage';
import QualitaDatiPage from '../pages/QualitaDatiPage';
import SupervisioneUmanaPage from '../pages/SupervisioneUmanaPage';
import DocumentazioneTecnicaPage from '../pages/DocumentazioneTecnicaPage';
import ValutazioneConformita from '../pages/ValutazioneConformita';

function Content( {username} ) {
    return (
        <div className="content content-page p-4 bg-light" style={{ marginLeft: '15.875rem', width: 'calc(100% - 250px)', minHeight: '100vh' }}>
            <Routes>
                <Route path="/" element={<DashboardPage />} />
                <Route path="/definizione-ambito" element={<DefinizioneAmbitoPage />} />
                <Route path="/valutazione-conformita" element={<ValutazioneConformita username={username}/>} />
                <Route path="/ai-alto-rischio" element={<AIAltoRischioPage />} />
                <Route path="/qualita-dati" element={<QualitaDatiPage />} />
                <Route path="/supervisione-umana" element={<SupervisioneUmanaPage />} />
                <Route path="/documentazione-tecnica" element={<DocumentazioneTecnicaPage />} />
                <Route path="*" element={<DashboardPage />} />
            </Routes>
        </div>
    );
}

export default Content;
