import React from 'react';

function DocumentazioneTecnicaPage() {
  return (
    <div>
      <h2>Documentazione Tecnica</h2>
      <p>Technical documentation for the project.</p>
    </div>
  );
}

export default DocumentazioneTecnicaPage;
