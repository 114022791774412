import React from 'react';

function DashboardPage() {
  return (
    <div>
      <h2>Dashboard</h2>
      <p>Welcome to the dashboard!</p>
    </div>
  );
}

export default DashboardPage;
