import React, { useState } from 'react';
import fornitore from '../json/fornitore.json';
import importatore from '../json/fornitore.json';
import distributore from '../json/fornitore.json';
import Chat from '../components/chat'

function ValutazioneConformita( {username} ) {
    const [currentQuestion, setCurrentQuestion] = useState('domanda_2');
    const [progress, setProgress] = useState(0);
    const [answers, setAnswers] = useState([]);
    const [selectedEntity, setSelectedEntity] = useState(null);
    const [questions, setQuestions] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [showResult, setShowResult] = useState(false);
    const [articles, setArticles] = useState([]);
    const [chatVisible, setChatVisible] = useState(false);
    const [resultHtml, setResultHtml] = useState(null);

    const updatePathInDatabase = (updatedPath) => {
        fetch('http://localhost:5000/path', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username, path: updatedPath.join(',') }),  // Inviamo l'array di domande come stringa separata da virgole
        }).then(res => res.json())
          .then(data => {
              if (data.success) {
                  console.log('Path updated successfully');
              } else {
                  console.error('Error:', data.error);
              }
          }).catch(err => console.error('Error updating path:', err));
    };

    const handleEntitySelection = (entity) => {
        setSelectedEntity(entity);
        setAnswers([]);
        setProgress(0);
        setCurrentQuestion('domanda_2'); // La prima domanda è "domanda_2"
        setSelectedOption(null); // Reset dell'opzione selezionata
        setShowResult(false);

        // Carica il JSON corretto in base all'ente selezionato
        switch (entity) {
            case 'fornitore':
                setQuestions(fornitore);
                setArticles(fornitore['domanda_2']?.articles || []); // Carica articoli della prima domanda
                break;
            case 'importatore':
                setQuestions(importatore);
                setArticles(importatore['domanda_2']?.articles || []); // Carica articoli della prima domanda
                break;
            case 'distributore':
                setQuestions(distributore);
                setArticles(distributore['domanda_2']?.articles || []); // Carica articoli della prima domanda
                break;
            default:
                setQuestions(null);
                setArticles([]);
        }
    };

    const handleOptionSelect = (option) => {
        setSelectedOption(option); // Seleziona l'opzione ma non avanza
    };

    const handleNext = () => {
        if (!selectedOption) return;

        const { answer, next, result } = selectedOption;
        const updatedAnswers = [...answers, { question: currentQuestion, answer }];
    
        // Verifica se la domanda corrente è già presente nel percorso
        let updatedPath = [...answers.map(a => a.question)];
    
        if (updatedPath[updatedPath.length - 1] !== currentQuestion) {
            updatedPath.push(currentQuestion);  // Aggiungi solo se non è già presente
        }
    
        // Aggiorna il percorso nel database senza duplicati
        updatePathInDatabase(updatedPath);
    
        setAnswers(updatedAnswers);

        if (result) {
            setShowResult(true);
            setProgress(100); // Imposta il progresso al 100%
            setResultHtml(result);
        } else if (next) {
            setCurrentQuestion(next);
            setProgress(progress + (100 / Object.keys(questions).length));
            setArticles(questions[next]?.articles || []);
        }

        setSelectedOption(null); // Reset dell'opzione selezionata per la prossima domanda
    };

    const handlePrevious = () => {
        const previousAnswer = answers.pop();
        setAnswers([...answers]);
        setCurrentQuestion(previousAnswer.question);
        setProgress(progress - (100 / Object.keys(questions).length));
        setArticles(questions[previousAnswer.question]?.articles || []);
        setSelectedOption(null);
    };

    const handleReset = () => {
        setCurrentQuestion('domanda_2');
        setAnswers([]);
        setProgress(0);
        setShowResult(false);
        setSelectedOption(null);
    };

    const toggleChat = () => {
        setChatVisible(!chatVisible);
    };

    const renderQuestion = (questionKey) => {
        if (!questions || !questions[questionKey]) return null;

        const questionData = questions[questionKey];
        return (
            <div>
                <h5>{questionData.question}</h5>
                {questionData.options && (
                    <div className="mt-3">
                        {questionData.options.map((option, index) => (
                            <div key={index} className="form-check">
                                <input
                                    type="radio"
                                    id={`option-${index}`}
                                    name="question"
                                    className="form-check-input"
                                    checked={selectedOption?.answer === option.answer}
                                    onChange={() => handleOptionSelect(option)}
                                />
                                <label className="form-check-label" htmlFor={`option-${index}`}>
                                    {option.answer}
                                </label>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="container-fluid mt-4 position-relative">
            <div className="row">
                {/* Lista Step */}
                <div className="col-md-8 mb-3">
                    <div className="card p-3">
                        <h5 className='text-uppercase'>Lista step</h5>
                        {/* Step di completamento con percentuale */}
                        <div className="d-flex align-items-center mt-3">
                            <div className="progress w-100" style={{ height: '8px' }}>
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{ width: `${progress}%` }}
                                />
                            </div>
                            <span className="ms-3">{Math.round(progress)}%</span>
                        </div>
                    </div>
                    {/* Nuova card per la selezione dell'ente */}
                    <div className="card p-3 mt-3">
                        <h5>Seleziona il tipo di ente</h5>
                        <div className="mt-4">
                            <button
                                className={`btn btn-outline-numina me-3 ${selectedEntity === 'fornitore' ? 'active' : ''}`}
                                onClick={() => handleEntitySelection('fornitore')}
                            >
                                Fornitore
                            </button>
                            <button
                                className={`btn btn-outline-numina me-3 ${selectedEntity === 'importatore' ? 'active' : ''}`}
                                onClick={() => handleEntitySelection('importatore')}
                            >
                                Importatore
                            </button>
                            <button
                                className={`btn btn-outline-numina ${selectedEntity === 'distributore' ? 'active' : ''}`}
                                onClick={() => handleEntitySelection('distributore')}
                            >
                                Distributore
                            </button>
                        </div>
                    </div>
                    <div
                        className={`${selectedEntity ? 'card p-3 mt-3' : ''} ${showResult ? 'd-none' : ''}`}
                        style={{ maxHeight: 'calc(100vh - 70px - 130px)' }}
                    >
                        {selectedEntity && (
                            <>
                                {renderQuestion(currentQuestion)}
                                <div className="d-flex justify-content-between mt-4">
                                    <button
                                        className="btn btn-secondary"
                                        onClick={handlePrevious}
                                        disabled={answers.length === 0}
                                    >
                                        Previous
                                    </button>
                                    <button
                                        className="btn btn-numina text-white"
                                        onClick={handleNext}
                                        disabled={!selectedOption}
                                    >
                                        Next
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                    {/* Se il questionario è completato e c'è un result */}
                    {showResult && resultHtml &&(
                        <div className="card p-3 mt-3">
                            <h5>Risultato</h5>
                            <div dangerouslySetInnerHTML={{ __html: resultHtml }} />
                            <button className="btn btn-numina mt-4" onClick={handleReset}>Reset</button>
                        </div>
                    )}
                </div>

                {/* Documentazione */}
                <div className="col-md-4">
                    <div className="card p-3 mb-3 overflow-auto" style={{ maxHeight: '35vh' }}>
                        <h5 className='text-uppercase'>Documentazione</h5>
                        {articles.map((article, index) => (
                            <div key={index}>
                                <p><b>{article.name}:</b>
                                    {article.description}</p>
                            </div>
                        ))}
                    </div>
                    <div className="card p-3 overflow-auto" style={{ maxHeight: '35vh' }}>
                        <h5 className='text-uppercase'>Altro Blocco</h5>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            Morbi consequat est tortor, sit amet euismod eros pharetra a.
                            Etiam imperdiet, lacus in finibus hendrerit, risus risus dictum quam,
                            sit amet sagittis metus magna eu massa. Donec commodo tempus fermentum.
                            Proin pharetra rutrum arcu nec lacinia. Fusce feugiat vitae dolor at rhoncus.
                            Morbi lacinia lacus sem, et porta sapien facilisis dictum. Cras consectetur
                            ante in libero congue, ac semper justo pharetra. Cras sodales nunc sit amet
                            odio elementum, in tempor nisl vulputate. Fusce facilisis finibus arcu
                            vitae sollicitudin. Sed tincidunt mi ligula, in
                            facilisis tellus ultricies quis.</p>
                    </div>
                </div>
            </div>

            {/* Chat Button */}
            <button
                className="btn btn-numina rounded-pill position-absolute shadow"
                onClick={toggleChat}
                style={{
                    bottom: '20px',
                    right: '20px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2) !important'
                }}
            >
                <span className="me-2" style={{ height: '10px', width: '10px', backgroundColor: '#fff', borderRadius: '50%', display: 'inline-block' }}></span>
                Chat with AI
            </button>
            {/* Chat */}
            {chatVisible && <Chat user={selectedEntity} path={answers.map(a => a.question)} questionId={currentQuestion} />}
        </div>
    );
}

export default ValutazioneConformita;
