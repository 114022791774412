import React from 'react';
import logo from '../images/logo-numina.png';

function Header({ username }) {
    return (
        <header className="navbar navbar-expand-lg navbar-dark bg-header fixed-top border-bottom border-light">
            <div className="container-fluid">
                <a className="navbar-brand ms-3" href="/">
                    <img src={logo} alt="Questo è il logo" height="27" />
                </a>
                <div className="d-flex">
                    <span className="navbar-text me-3">Benvenuto, {username}</span>
                    <button className="btn btn-outline-light me-3">Logout</button>
                </div>
            </div>
        </header>
    );
}

export default Header;
