import React from 'react';

function QualitaDatiPage() {
  return (
    <div>
      <h2>Qualità Dati</h2>
      <p>Manage the data quality here.</p>
    </div>
  );
}

export default QualitaDatiPage;
