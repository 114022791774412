import React, { useState, useEffect } from 'react';
import Header from '../components/header';
import Sidebar from '../components/sidebar';
import Content from '../components/content';

function Dashboard({ userInfo }) {
    const [projectDescription, setProjectDescription] = useState('');
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        const fetchProjectDescription = async () => {
            try {
                const response = await fetch(`http://localhost:5000/project/${userInfo.username}`);
                if (response.ok) {
                    const data = await response.json();
                    setProjectDescription(data.projectDescription);
                } else {
                    console.error('Error fetching project description');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchProjectDescription();
    }, [userInfo.username]);

    const handleSave = async () => {
        try {
            const response = await fetch(`http://localhost:5000/project/${userInfo.username}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ projectDescription }),
            });

            if (response.ok) {
                setIsEditing(false);
            } else {
                console.error('Error updating project description');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };


    return (
        <div>
            <Header username={userInfo.username} />
            <div className="d-flex">
                <Sidebar />
                <Content username={userInfo.username}/>
            </div>
        </div>
    );
}

export default Dashboard;
