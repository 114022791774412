import React from 'react';
import { NavLink } from 'react-router-dom';
import { MdSpaceDashboard } from "react-icons/md";
import { CgDanger } from "react-icons/cg";
import { MdCategory } from "react-icons/md";
import { MdEngineering } from "react-icons/md";
import { MdOutlineChecklist } from "react-icons/md";
import { MdHighQuality } from "react-icons/md";
import { MdEditDocument } from "react-icons/md";

function Sidebar() {
  return (
    <nav className="d-flex flex-column bg-sidebar text-white vh-100 p-3" style={{ width: '15.875rem', position: 'fixed', top: 58, left: 0 }}>
      <h4 className="mb-4">Menu</h4>
      <ul className="nav flex-column">
        <li className="nav-item">
          <NavLink to="/dashboard" className="nav-link text-white link-underline-opacity-100-hover" activeclassname="active"><MdSpaceDashboard /> Dashboard</NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/dashboard/definizione-ambito" className="nav-link text-white link-underline-opacity-100-hover" activeclassname="active link-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"><MdCategory/> Definizione Ambito</NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/dashboard/valutazione-conformita" className="nav-link text-white link-underline-opacity-100-hover" activeclassname="active"><MdOutlineChecklist/> Valutazione Conformità</NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/dashboard/ai-alto-rischio" className="nav-link text-white link-underline-opacity-100-hover" activeclassname="active"><CgDanger/> AI ad Alto Rischio</NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/dashboard/qualita-dati" className="nav-link text-white link-underline-opacity-100-hover" activeclassname="active"><MdHighQuality/> Qualità Dati</NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/dashboard/supervisione-umana" className="nav-link text-white link-underline-opacity-100-hover" activeclassname="active"><MdEngineering/> Supervisione Umana</NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/dashboard/documentazione-tecnica" className="nav-link text-white link-underline-opacity-100-hover" activeclassname="active"><MdEditDocument/> Doc Tecnica</NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default Sidebar;
